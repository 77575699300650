import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown/with-html';

import styles from './text-under-picture-event.module.styl';
import backgroundDefault from '../../images/default.jpg';

const TextUnderPictureEvent = ({
  subject,
  text,
  link,
  momentTz,
  startTime,
  archive,
  image,
  series,
}) => {
  const textWithoutBreaks = text.replace(/(\r\n|\n|\r)/gm, '');
  const mainText = textWithoutBreaks.replace(/^(.{100}[^\s]*).*/, '$1');

  return (
    <div className={styles.card}>
      <Link to={link} className={styles.link}>
        <img src={image || backgroundDefault} alt={subject} className={styles.image} />
        <div className={styles.container}>
          {series ? (
            <div className={styles.startTime}>{series}</div>
          ) : (
            <time className={styles.startTime}>
              {moment(startTime, 'X')
                .tz(momentTz)
                .format('MMM D YYYY @ hh:mm A')}
            </time>
          )}
          <h1 className={styles.subject}>{subject}</h1>
          <div className={styles.mainText}>
            <ReactMarkdown source={mainText} escapeHtml={false} />
            (...)
          </div>
        </div>
      </Link>
    </div>
  );
};

TextUnderPictureEvent.propTypes = {
  subject: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  series: PropTypes.string,
  rsvpUrl: PropTypes.string,
  momentTz: PropTypes.string,
  image: PropTypes.string,
  typeBlog: PropTypes.bool,
};

export default TextUnderPictureEvent;
