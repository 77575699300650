import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import AOS from 'aos';

import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import TextUnderPictureEvent from '../components/text-under-picture-event/text-under-picture-event';
import * as PropTypes from 'prop-types';
import styles from './archive-page.module.styl';
import { parseRef } from '../services/local-storage';

class ArchivePage extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init({
      once: true,
      duration: 1000,
    });

    parseRef(this.props.location);
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    let { data, pageContext } = this.props;
    const pagination = [];

    for (let i = 0; i < pageContext.numberOfPages; i += 1) {
      const index = i + 1;

      pagination.push({
        url: index === 1 ? '/archive/' : `/archive/${index}/`,
        active: i === pageContext.pageNumber,
        text: `${index}`,
      });
    }

    return (
      <Layout>
        <SEO
          title="Archive"
          description="Dating events for singles. Dating should be fun. Your dream date might be just an event away!"
        />
        <Header />
        <div className={styles.eventsLinkContainer} data-aos="fade-up">
          <Link to={'/'} className={styles.archiveLink}>
            Current events
          </Link>
        </div>
        <section className={`row ${styles.container}`} data-aos="fade-up">
          {data.allEventsJson.edges.map(({ node }, index) => (
            <TextUnderPictureEvent
              key={node.id}
              subject={node.Subject}
              startTime={node.Start}
              archive={node.archive}
              text={node.FullText}
              image={node.Image}
              momentTz={node.TimeZone}
              series={node.Series}
              link={`/events/${node.id}/`}
            />
          ))}
        </section>
        <div className={styles.archiveLinkContainer} data-aos="fade-up">
          {pagination.map(item => {
            if (!item.active) {
              return (
                <Link to={item.url} className={styles.archiveLink} key={item.text}>
                  {item.text}
                </Link>
              );
            } else {
              return (
                <span className={styles.archiveLinkBlocked} key={item.text}>
                  {item.text}
                </span>
              );
            }
          })}
        </div>
        <Footer />
      </Layout>
    );
  }
}

ArchivePage.propTypes = { data: PropTypes.any };

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allEventsJson(
      filter: { archive: { eq: true } }
      sort: { fields: [Start], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          Subject
          Start
          RSVP
          FullText
          TimeZone
          Image
          archive
          Start
        }
      }
    }
  }
`;

export default ArchivePage;
